.about-me-sponsoring-container {
        padding-top: 30px;
        padding-bottom: 30px;
        width: auto;
        margin: auto;
        padding-top:50px;
        font-family: 'Raleway', serif;
        font-size: 1rem;
        width: 100%;
        display: column;
        justify-content: center;
        align-items: center;
        text-align: center;

}
.about-me-sponsoring-heading {
    width: 80%;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding-bottom: 20px;
}

.about-me-sponsoring-content-container {
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    vertical-align: top;

}
.about-me-sponsoring-content-image {
    min-width: 320px; 
    object-fit: cover;
    padding-bottom: 20px;
}
.about-me-sponsoring-content-text {
    width: 50%;
    text-align: left;
}

@media screen and (max-width: 960px) {

.about-me-sponsoring-content-text {
    width: 80%;
    margin: auto;
    text-align: left;
}
}