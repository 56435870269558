.introduction-text-container{
    font-family: 'Raleway', serif;
    display: row;
    margin:100px auto;
    justify-content: center;
    align-items: center;
    background: white;
    width: 50%;
}
.introduction-text-heading{
    text-align: left;
    font-size: 1.5rem;
    border-bottom: 2px solid rgb(4, 110, 177);
}
.introduction-text {
    font-size: 1rem;
    text-align: block;
}


@media screen and (max-width: 960px) {
    .introduction-text-heading{
        text-align: center;
        font-size: 1rem;
    }
    .introduction-text-container{
        display: row;
        margin: 20px auto;
        justify-content: center;
        align-items: center;
        background: white;
        width: 80%;
    }
}