.process-content-rights-container{
    padding-bottom: 30px;
    display: column;
    width: auto;
    margin: auto;
    padding-top:30px;
    font-family: 'Raleway', serif;
    font-size: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.process-content-rights-heading{
    width: 80%;
    font-size: 1.5rem;
    margin: auto;
    justify-content: left;
    align-items: left;
    text-align: left;
}