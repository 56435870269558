.about-me-contact-container{
        padding-bottom: 30px;
        width: auto;
        margin: auto;
        padding-top:50px;
        background: rgb(4, 110, 177);
        color:white;
        font-family: 'Raleway', serif;
        font-size: 1rem;
        width: 100%;
        display: column;
        justify-content: center;
        align-items: center;
        text-align: center;
}
.about-me-contact-heading{
    padding-bottom: 30px;
    font-size: 1.8rem;
}
.about-me-contact-text{
    width: 60%;
    margin:auto;
    font-size: 1.2rem;
    padding-bottom: 30px;
}
.about-me-contact-text-info{
    width: 60%;
    margin:auto;
    font-size: 1.5rem;
    padding-bottom: 30px;
}

.link{
    color: inherit;
}


@media screen and (max-width: 960px) {
    .about-me-contact-text{
        width: 100%;
        margin:auto;
        font-size: 1rem;
        padding-bottom: 30px;
    }
    .about-me-contact-text-info{
        width: 60%;
        margin:auto;
        font-size: 1.2rem;
        padding-bottom: 30px;
    }
}