.process-head-container {
    height: 500px;
    width: 100%;
    background: url(../../../media/process/road-accident-with-smashed-cars.jpg) no-repeat center fixed;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text-container {
    font-family: 'Raleway', serif;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #046EB1;
    opacity: 0;
    transition: 3s opacity;
    height: 200px;
    width: 50%;
    object-fit: contain;
    font-size: 2em;
    text-align: center;
}

.text-container-fade-in {
    opacity: 0.8;
}