.process-way-container{
    padding-top: 30px;
    padding-bottom: 30px;
    width: auto;
    margin: auto;
    padding-top:50px;
    background: rgb(4, 110, 177);
    color:white;
    font-family: 'Raleway', serif;
    font-size: 1rem;
    width: 100%;
    
}
.process-way-heading{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}
.process-way-title{
    font-size: 2.5rem;
    width: 50%;
}
.process-way-image{
    min-width: 320px;
        width: 30%; 
        height: 30%; 
        object-fit: cover;
}
.process-way-steps-container{
    padding-bottom: 20px;
}

@media screen and (max-width: 960px) {
    .process-way-image{
        display: none;
    }
}
