.introduction-damage-case-outer-container{
    background: rgb(4, 110, 177);
    padding: 80px 0px 80px 0px;
    
}
.introduction-damage-case-inner-container{
    display: row;
    margin:auto;
    justify-content: center;
    align-items: center;
    width: 80%;
}
.introduction-damage-case-text {    
    font-family: 'Raleway', serif;
    font-size: 1rem;
    color: white;
    text-align: center;
}
.link{
    color: inherit;
}
