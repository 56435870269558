.impressum-container{
    display: flex;
    padding-bottom: 30px
}

.impressum-content-container{
    width: 80%;
    margin: auto;
    font-family: 'Raleway', serif;
    justify-content: left;
    text-align: left;
}
.impressum-content-title{
    padding-top:30px;
    font-size: 1.5rem;
}
.impressum-content{
    padding-top:30px;
    font-size: 1rem;
}
.link{
    color: inherit;
}