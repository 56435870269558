.navbar {
  background: white;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  font-family: 'Raleway', serif;
  opacity: 1;
  border-bottom: 10px solid rgb(4, 110, 177);
}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}
