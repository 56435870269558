.introduction-card-image{
    margin: 20px;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 30%;
    color:white;
    min-width: 320px;
}

.img {
    width: 100%; 
    height: 100%; 
    object-fit: cover;
}
.introduction-card-text{
    width: 100%;
    position: absolute;   
    bottom: 0px;
    background-color: #046EB1;
    opacity: 0.8;
    font-size: 0.9rem;
}
.introduction-card-text:hover{
    width: 100%;
    position: absolute;   
    bottom: 0px;
    background-color: white;
    color: #046EB1;
    opacity: 0.8;
    font-size: 0.9rem;
}

