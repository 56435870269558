:root {
    --primary: #046eb1;
}
.btn-mobile{
    justify-content: center;
    align-self: center;
}
.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}
.btn--primary {
    background-color: var(--primary);
    color: white;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #046eb1;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background: #046eb1;
    color:white;
    transition: all 0.3s ease-out;
}

