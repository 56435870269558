@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
   url("./fonts/Raleway-VariableFont_wght.ttf") format("truetype");
  }

*{
    margin: 0;
    padding: 0;
  }
  
  .app-overall{
    width: 100%;
  }
