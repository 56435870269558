.introduction-header {
  font-size: 1rem;
  color: rgb(4, 110, 177);
  font-family: "Raleway", serif;
  margin: 100px auto;
  padding-top: 30px;
}
.introduction-card-container {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  .introduction-header {
    font-size: 1rem;
    color: rgb(4, 110, 177);
    font-family: "Raleway", serif;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
  }
}
