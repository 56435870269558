.box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: left;
  text-align: left;
  width: 100%;
  font-family: 'Raleway', serif;
  font-size: 1rem;
  object-fit: cover;
  padding-bottom: 30px ;
}
.box-image {
    order:0;
  min-width: 320px;
  max-width: 320px;
  object-fit: cover;
  padding-right: 30px;
}
.box-text {
    order:1;
  width: 40%;
}
.box-text-heading {
    font-weight: bold;
  font-size: 1.5rem;
  padding-bottom: 20px;
}
.box-text-para{
    padding-bottom: 20px;
}
@media screen and (max-width: 1020px) {
  
  .box-image{
      order:1;
  }
  .box-text {
    order:0;
    width: inherit;
    padding-left: 0px;
  }
}
