.footer{
  align-items: center;
  font-family: 'Raleway', serif;
  font-size: 1rem;
  border-top: 5px solid rgb(4, 110, 177);
  color:white;
  background-color: rgb(4, 110, 177);
  padding: 50px;
}

.footer-container {
  width: 80%;
  padding-left: 15%;    
  display: flex;
  flex-wrap: wrap;
}

.footer-element{
  flex-grow: 1;
}
.line-padding{
  padding-top: 20px;
  padding-bottom: 10px;
}

