.process-way-step-container{
    padding-bottom: 10px;
    padding-top:10px;
    margin: auto;
    font-family: 'Raleway', serif;
    font-size: 1rem;
    width: 90%;
    align-items: center;
    text-align: left;
}
.process-way-step-heading{
    display: flex;
    padding: 10px;
}
.process-way-step-heading-number{
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 20%;
    font-size: 1.5rem;
}
.circle {
    margin: auto;
    width: auto;
    border: 0.1em solid white;
    background-color: white;
    border-radius: 100%;
    height: 2em;
    width: 2em;
}

.circle p {
    color: rgb(4, 110, 177);
    font-size: 1.5em;
    font-weight: bold;
}

.process-way-step-heading-title{
    font-size: 1.5rem;
    width: 80%;
    padding-left: 10px;
}
.process-way-step-content{
    display: flex;
}
.process-way-step-content-line-left{
    width: 10%;
    height: 100%;
     
}

.process-way-step-content-line-right{
    width: 10%;
    display: inline-block;
    border-left: 1px solid white;
    margin: 0 10px;
}

.process-way-step-content-text{
    width: 80%;
    font-size: 1rem;
}
