.as-container{
    width: 100%;
    font-family: 'Raleway', serif;
    justify-content: left;
    align-items: left;
    padding-top: 30px;
}
.as-content{
    width: 80%;
    margin:auto;
    overflow: hidden;
}
.as-content-heading{
    font-size: 1.5rem;
    font-weight: bolder;
    border-bottom: 2px solid rgb(4, 110, 177);
    padding-bottom: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.as-blue{
    color:white;
    background: rgb(4, 110, 177);
}
.as-blue-heading{
    border-bottom: 2px solid rgb(255, 255, 255);
    padding-bottom: 30px;
    margin-bottom: 30px;padding-top: 30px;
    padding-bottom: 30px;
    font-family: 'Raleway', serif;
    font-size: 1.5rem;
    font-weight:bold;

}
.as-container-blue{
    width: inherit;
    color:white;
    background: rgb(4, 110, 177);
}
@media screen and (max-width: 960px) {
    .as-content-heading-text{
        width: 60%;
    }
}