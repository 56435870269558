.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
}

.nav-menu.active {
  background:white;
  position: absolute;
  left: 0;
  opacity: 1;
  z-index: 999;
  padding-bottom: 20px;
}

.nav-links {
  margin: auto;
  justify-content: center;
  text-align: center;
  display: table;
}

.nav-links:hover {
  background-color: #046eb1;
  color: white;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

.menu-icon {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 2.5rem;
  cursor: pointer;
}

.fa-times {
  color: #046eb1;
  font-size: 2rem;
}
