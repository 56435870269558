.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  background: rgba(0, 0, 0, 0.9);
  width: 100vw;
  height: 100vh;

}

.modal-main {
  text-align: center;
  color: white;
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}
.modal-button {
  color: rgba(0, 0, 0, 0.9);
  background-color: white;
  margin-top: 10px;
  border: 1px solid;
  border-radius: 2px;
  border-color: white;
}
