.process-content-law-container{
    padding-top: 30px;
    padding-bottom: 30px;
    width: auto;
    margin: auto;
    padding-top:50px;
    background: rgb(4, 110, 177);
    color:white;
    font-family: 'Raleway', serif;
    font-size: 1rem;
    width: 100%;
    display: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.process-content-law-heading{
    font-size: 1.5rem;
}

.process-content-law-text{
    margin: auto;
    width: 80%;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 1rem;
}