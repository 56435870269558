.process-content-container{
    padding-top:50px;
    font-family: 'Raleway', serif;
    font-size: 1rem;
    width: 100%;
    display: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 50px;
}
.process-content-headline{
    margin: auto;
    width: 60%;
    font-size: 3rem;
}
.process-content-head-container{
    padding-top: 50px;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 100%;
}
.process-content-head-text{
    margin: auto;
    width: 80%;
    font-size: 1.5rem;
    
}
.li {
  list-style-type: none;
  position: relative;
  padding-left: 15px;
}

.li:before {
  content: "\25BA \0020";
  font-size: 20px;
  position: absolute;
  top: 0px;
  left: -10px;
}

@media screen and (max-width: 960px) {
    .process-content-headline{
        font-size: 1.4rem;
        font-weight: bold;
        width: 80%;
    }
    .process-content-head-text{
        font-size: 1rem;
        padding-bottom: 20px;
    }
}