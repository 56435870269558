.about-me-bio-container {
  font-family: 'Raleway', serif;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.about-me-bio-content {
  width: 80%;
  margin: auto;
  padding-bottom: 30px;
}
.about-me-bio-title {
  font-size: 1.5rem;
  
}
.about-me-bio-underline{
  border-bottom: 2px solid rgb(4, 110, 177);
}
.about-me-bio-text {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem;
}
.about-me-bio-image {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 80%;
  margin: auto;
  min-width: 250px; 
  object-fit: cover;
  justify-content: left;
  align-items: left;
  text-align: left;
}

