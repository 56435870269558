
.nav-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}
  button {
    font-size: 1.2rem;
    font-family: 'Raleway', serif,
      "Lucida Sans", Arial, sans-serif;
    background: inherit;
    color:inherit;
    border: none;
    align-items: center;
  }
 
  .navbar-text {
    color: black;
    margin-left: 10px;
    padding-right: 30px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
  }
    
  
  .nav-links {
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  .dropdownmenu-text {
    display: flex;
  }
  
  .fa-sort-down {
    padding-left: 5px;
  }
  .nav-links-dropdownmenu {
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.3rem 0.8rem;
    height: 100%;
  }
  
  .dropdownmenu-container {
    display: column;
    background: white;
    margin-top: 20px;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
  }
  
  .nav-links:hover {
    transition: all 0.4s ease-in;
  }
 